import './App.css';
import {Heading} from 'evergreen-ui'
import {Pane, TextInput} from 'evergreen-ui'
import {Badge} from 'evergreen-ui'
import Amplify, {API, graphqlOperation} from 'aws-amplify';
import awsconfig from './aws-exports';
import {AmplifySignOut, withAuthenticator} from '@aws-amplify/ui-react'
import {listKnots} from './graphql/queries';
import {createRef, useState} from 'react';
import {useEffect} from "react";
import {Table} from 'evergreen-ui'
import {createKnot, updateKnot} from "./graphql/mutations";
import {Button} from 'evergreen-ui'

Amplify.configure(awsconfig)

function App() {

    const [knots, setKnots] = useState([]);
    let txtId = createRef();
    let txtName = createRef();
    let txtType = createRef();

    useEffect(()=>{getKnots();},[])

    const getKnots = async() => {
        try {
            const knotData = await API.graphql(graphqlOperation(listKnots))
            const knotList = knotData.data.listKnots.items;
            console.log('knot list', knotList);
            setKnots(knotList)
        } catch (error) {
            console.log('error on fetching knots', error);
        }
    }

    const toggleActive = async(idx) => {
        try {
            const knot = knots[idx];
            if (knot.type==="y"){
                knot.type = "x";
            }
            else {
                knot.type = "y";
            }

            delete knot.createdAt;
            delete knot.updatedAt;
            const knotData = await API.graphql(graphqlOperation(updateKnot, {input: knot}));
            const knotList = [...knots];
            knotList[idx] = knotData.data.updateKnot;
            setKnots(knotList);

        } catch(error) {
            console.log('error on togglin', error);
        }
    }

    const addKnot = async () => {
        try {
            const vnewID = txtId.current.value;
            const vname = txtName.current.value;
            const vtype = txtType.current.value;
            const data = {id:vnewID, name:vname, type:vtype};
            const knotData = await API.graphql(graphqlOperation(createKnot, {input: data}))
            getKnots();
        } catch (error) {
            console.log('error on adding Knot', error);
        }
    }

  return (
    <div className="App">
      <header className="App-header">

          <AmplifySignOut />

          <div>
              <div>
                <Heading size={1400} color={'#ffffff'}>τυλος</Heading>
              </div>
              <br/>
              <br/>
              <div className="knotList">

                      <Table>
                          <Table.Head>
                              <Table.TextHeaderCell>id</Table.TextHeaderCell>
                              <Table.TextHeaderCell>name</Table.TextHeaderCell>
                              <Table.TextHeaderCell>type</Table.TextHeaderCell>
                              <Table.TextHeaderCell></Table.TextHeaderCell>
                          </Table.Head>
                          <Table.Body height={800}>
                              {knots.map((knot, idx) => (
                                  <Table.Row key={`knot${idx}`} isSelectable>
                                      <Table.TextCell>{knot.id}</Table.TextCell>
                                      <Table.TextCell>{knot.name}</Table.TextCell>
                                      <Table.TextCell>{knot.type}</Table.TextCell>
                                      <Table.TextCell>
                                          <Button marginRight={16} appearance="primary" intent="none" onClick={()=>toggleActive(idx)}>
                                              Update
                                          </Button>
                                      </Table.TextCell>
                                  </Table.Row>
                              ))}
                              <Table.Row>
                                  <Table.TextCell>
                                      <TextInput name="text_input_id" ref={txtId} placeholder="new id..." />
                                  </Table.TextCell>
                                  <Table.TextCell>
                                      <TextInput name="text_input_name" ref={txtName} placeholder="new text..." />
                                  </Table.TextCell>
                                  <Table.TextCell>
                                      <TextInput name="text_input_type" ref={txtType} placeholder="new type..." />
                                  </Table.TextCell>
                                  <Table.TextCell>
                                      <Button marginRight={16} appearance="primary" intent="danger" onClick={()=>addKnot()}>
                                      Add
                                      </Button>
                                  </Table.TextCell>
                              </Table.Row>
                          </Table.Body>
                      </Table>

              </div>
              <div>
                  <Pane>
                      <Badge color="neutral" marginRight={8}>
                          neutral
                      </Badge>
                      <Badge color="green" marginRight={8}>
                          green
                      </Badge>
                      <Badge color="blue" marginRight={8}>
                          blue
                      </Badge>
                      <Badge color="red" marginRight={8}>
                          red
                      </Badge>
                      <Badge color="orange" marginRight={8}>
                          orange
                      </Badge>
                      <Badge color="purple" marginRight={8}>
                          purple
                      </Badge>
                      <Badge color="yellow" marginRight={8}>
                          yellow
                      </Badge>
                      <Badge color="teal" marginRight={8}>
                          teal
                      </Badge>
                  </Pane>
              </div>
          </div>

      </header>
    </div>)
}

export default withAuthenticator(App);
