/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_identity_pool_id": "eu-west-1:f4915115-aced-43e6-ab21-8a3f553dd8d1",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_q65qcuEHF",
    "aws_user_pools_web_client_id": "hgqlsso48ifgl6pf90hguf5di",
    "oauth": {},
    "aws_dynamodb_all_tables_region": "eu-west-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "knot-dev",
            "region": "eu-west-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://qedh22ghcfc5fnh4fek75cvy2e.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS"
};


export default awsmobile;
